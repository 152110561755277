import React from "react"
import { useQuery } from "urql"

import Container from "../components/Container"
import Title from "../components/Title"

const OurStaffSection = () => {
  const [{ data }] = useQuery({
    query: `
  query Teachers {
    teachers(last: 100) {
      name
      id
      position
    }
  }
`,
  })

  return (
    <Container as="section" classes="our-staff">
      <Title as="h2" size="2">
        Grono pedagogiczne
      </Title>
      <ul className="our-staff__list">
        {data?.teachers.map(({ id, name, position }) => (
          <li key={id}>
            <strong>{name}</strong> - {position}
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default OurStaffSection
