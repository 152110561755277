import React from "react"
import Container from "../components/Container"
import Title from "../components/Title"

const MottoSection = () => {
  return (
    <Container as="section" classes="motto">
      <Title size="3" as="p" fontFamily="serif" color="primary">
        "Uczymy się nie dla szkoły, lecz dla życia"
        <br />
        Seneka Młodszy
      </Title>
    </Container>
  )
}

export default MottoSection
