import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Container from "../components/Container"
import Title from "../components/Title"
import Text from "../components/Text"

const PatronFullSection = () => {
  return (
    <Container as="section" classes="patron-full" id="patron">
      <Title as="h2" size="2" color='light'>
        O naszym patronie
      </Title>
      <StaticImage src="../assets/images/patron.jpg" alt="patron" className="patron-full__image"/>
      <Text classes="patron-full__text" color='light'>
        Emilia Sczaniecka pochodziła z jednego z rodów szlacheckich w
        Wielkopolsce. Urodziła się 20 maja 1804 r. w Brodach. Rodzicami jej byli
        Łukasz Sczaniecki oraz Weronika z Wyskota Zakrzewskich, córka rotmistrza
        wojsk polskich oraz łowczego gnieźnieńskiego. Emilia miała czworo
        rodzeństwa: dwóch braci: Stanisława i Konstantego oraz dwie siostry:
        Nimfę i Kordulę. Wczesne dzieciństwo spędziła w spokoju i szczęściu. W
        lipcu 1810 roku bardzo boleśnie przeżyła śmierć ojca. Od tego czasu
        inicjatywę w rodzinie przejęła doświadczona w zarządzaniu majątkiem
        matka Łukasza Sczanieckiego – Anastazja.
        <br />
        Jesienią 1813 roku dziewięcioletnia Emilia i jej starsza siostra Nimfa
        rozpoczęły naukę na pensji żeńskiej kierowanej przez Jana Samuela
        Kaulfussa, który był jedną z największych indywidualności w ówczesnej
        Wielkopolsce. Przeszły tam edukację wszechstronną, która obejmowała
        znajomość czterech języków (polskiego, niemieckiego, francuskiego i
        włoskiego), przyrodę rysunki, malarstwo, urządzanie mieszkań, roboty
        ręczne i muzykę.
        <br />
        Na ukształtowanie się osobowości Emilii Sczanieckiej wywarł wielki wpływ
        cały okres istnienia Księstwa Warszawskiego (1807 - 1815). Panowała
        silnie patriotyczna atmosfera. Emilia wzrastała nie będąc obojętna na
        otaczające zjawiska.
        <br />
        Kolejnym ciężkim ciosem dla Emilii Sczanieckiej była śmierć matki w
        sierpniu 1818 roku. Teraz całkowitą opiekę nad czwórką osieroconych
        dzieci zajęła się ich babcia. Postanowiła ona przerwać naukę Nimfy i
        Emilii, które miały zamieszkać z nią w Wąsowie. Emilia jednak myślała o
        dalszej edukacji. Ostatecznie wiosną 1819 roku udało jej się osiągnąć
        cel, gdyż rada familijna postanowiła ulokować Emilię i Kordulę na pensji
        Laforgue'ów w Dreźnie. Drezno, Berlin i Wrocław były ośrodkami
        grupującymi silną Polonię.
        <br />
        W Dreźnie Emilia spotkała się po raz pierwszy z polską konspiracyjną
        działalnością niepodległościową. Swoją edukację w tym mieście ukończyła
        w roku 1823. Zdobyła wykształcenie i obycie odpowiednie dla swej
        pozycji. Przygotowała się także do roli właścicielki majątku, gdyż w
        spadku po rodzicach otrzymała Pakosław, Michorzewo i Michorzewko.
        <br />
        Emilia dość szybko nauczyła się praktycznego podejścia do obieranych
        przedsięwzięć i raczej nie decydowała się na działanie bez perspektywy
        uzyskania konkretnych efektów.
        <br />
        W 1830 roku Sczaniecka wyjechała do Paryża, gdzie w kontaktach z
        miejscowymi środowiskami demokratycznymi ugruntowała swoje przekonania;
        odtąd też już regularnie utrzymywała potajemny kontakt ze wszystkimi
        kolejnymi pokoleniami emigracji polskiej, bez względu na kierunek
        polityczny.
        <br />
        Przełom w życiu panny Emilii nastąpił w grudniu 1830 roku, gdy
        zaangażowała się w organizowanie pomocy dla Wielkopolan, którzy
        potajemnie przekroczyli granicę Królestwa Polskiego, by wziąć udział w
        powstaniu listopadowym. Wkrótce sama znalazła się w Warszawie, gdzie u
        boku Klaudyny Potockiej, pod kierunkiem Klementyny z Tańskich
        Hoffmanowej poznała trudy pracy w lazarecie polowym.
        <br />
        Po powrocie w rodzinne strony znalazła się pod nadzorem policyjnym, ale
        nie zerwała kontaktu z działaczami niepodległościowymi, a także z
        kręgami Wielkiej Emigracji. Zaangażowana była w głównych inicjatywach
        organicznikowskich regionu (m.in. w Spółkę Bazarową), ale nie
        odżegnywała się od udziału w przygotowaniach do walki zbrojnej. Jednak
        widziała w niej dla siebie rolę nie na polu bitwy, lecz w szpitalu
        polowym; zresztą swe umiejętności mogła rozszerzyć w trakcie walki z
        epidemia cholery w 1837 roku. Dwa lata wcześniej paryskie charytatywne
        Towarzystwo Montyona i Franklina przyznało Sczanieckiej złoty medal za
        poświęcenie w pracy samarytańskiej w szpitalach warszawskich.
        <br />
        Kolportowała publikacje emigracyjne (m.in. autorstwa Adama Mickiewicza i
        Wincentego Pola), zaangażowała się w pomoc dla aresztowanego przez
        władze pruskie arcybiskupa Marcina Dunina, a mieszkając czasowo w
        Berlinie utworzyła aktywny krąg polonijny.
        <br />
        Kolejnym czasem chwały panny Emilii były wydarzenia wielkopolskiej
        Wiosny Ludów, kiedy założyła Dyrekcję Opieki nad Rannymi – organizację
        kobiet pracujących w lazaretach polowych. Podobnie działała w latach
        1863 - 1864, gdy założyła szpital polowy w Strzelnie, udzielający pomocy
        rannym uczestnikom Powstania Styczniowego. Potem zajmowała się
        zapewnianiem ochrony uczestnikom walk z Wielkopolski, zagrożonym
        prześladowaniami policyjnymi.
        <br />
        W 1873 roku zmarł ostatni z rodzeństwa panny Emilii - Konstanty. Emilia
        nie pozostawała bierna. Była współorganizatorką poznańskiej filii
        działającego w Toruniu od 1870 r. Towarzystwa Pomocy Naukowej dla
        Dziewcząt. Chodziło jej o stworzenie kobietom możliwości zdobycia
        kwalifikacji, umiejętności pozwalających im zarobkować w razie potrzeby.
        Do końca życia była przewodniczącą honorową tej organizacji i wspierała
        ją finansowo.
        <br />
        Warto wspomnieć, iż z czasem została też członkiem honorowym
        Stowarzyszenia Nauczycielek w Krakowie, Towarzystwa Czytelni dla Kobiet
        we Lwowie, towarzystwa Warta i Towarzystwa Opieki nad Dziećmi Polskimi.
        <br />
        W 1877 r. Emilia Sczaniecka założyła również w Pakosławiu ochronkę,
        czyli przedszkole dla dzieci wiejskich, których rodzice byli zajęcie
        pracą w polu.
        <br />
        W 1892 roku Emilia Sczaniecka utraciła możność samodzielnego poruszania
        się i odtąd była wożona w fotelu na kółkach. Cały czas wielokrotnie
        wypowiadała się na temat narastającego kursu antypolskiego. Podkreślała
        jak ważne jest jawne bądź konspiracyjne nauczanie historii i języka
        ojczystego.
        <br />
        Panna Emilia do końca życia zachowała trzeźwość umysłu, jedynie na
        tydzień przed śmiercią straciła słuch. Zmarła 11 maja 1896 roku w
        Pakosławiu.
        <br />
        Pogrzeb Emilii Sczanieckiej odbył się 15 maja 1896 roku. Pochowano ją na
        przykościelnym cmentarzu w Michorzewie. W prasie polskiej ukazały się
        liczne nekrologi i wspomnienia pośmiertne. Odbyły się akademie żałobne i
        msze za duszę panny Emilii. Emilia Sczaniecka nie dążyła do chwały i
        zaszczytów. Zdobyła je sobie jedynie dzięki ciężkiej pracy
        organizacyjnej i patriotycznej. Stała się uosobieniem serdeczności i
        ofiarności, a jej cnoty patriotyczne i moralne mają walory ponadczasowe.
        <br />
        <br />
        Krystyna Loba
      </Text>
    </Container>
  )
}

export default PatronFullSection
