import React from "react"
import Container from "../components/Container"
import Title from "../components/Title"

const BannerSection = props => {
  const { title, image } = props

  return (
    <Container fluid as="header" classes="header">
      {image}
      <div className="container header__content" data-type={"default"}>
        <Title as="h1" size="1" fontFamily="serif" color="primary">
          {title}
        </Title>
      </div>
    </Container>
  )
}

export default BannerSection
