import * as React from "react"

// Sections
import BannerSection from "../sections/BannerSection"
import OurStaffSection from "../sections/OurStaffSection"
import PatronFullSection from "../sections/PatronFullSection"
import MottoSection from "../sections/MottoSection"
import Seo from "../components/seo"

import szkola from '../assets/images/szkola-3.jpg'

const IndexPage = () => {
  return (
    <>
      <BannerSection title="Poznaj naszą szkołę" image={<img src={szkola} alt="szkoła" />}/>
      <MottoSection />
      <PatronFullSection />
      <OurStaffSection />
    </>
  )
}

export const Head = () => (
  <Seo
    title="O nas"
    description="Szkoła Podstawowa im. Emilii Sczanieckiej we Lwówku."
  />
)
export default IndexPage
